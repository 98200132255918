import * as PropTypes from "prop-types";

export const nodeType = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  append_space: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  expand_on_click: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired, // No easy known way to handle recursion here.
};

export const processedNodeType = {
  ...nodeType,
  active: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};
