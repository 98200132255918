import React from "react";
import * as PropTypes from "prop-types";
import {nodeType} from "./node_type";
import {NavGroup} from "./NavGroup";
import {prepareNodes} from "./nav_helpers";
import {Langselect} from "./Langselect";

export class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: this.prepareNodes(props.nodes, props.activePageId),
      activePageId: props.activePageId,
      manuallyExpanded: [],
      visible: false, // affects only small screens – always visible on larger screens.
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleHideNav = this.handleHideNav.bind(this);
  }

  componentDidMount() {
    document.onNavPageSelect = (pageId, animate) => {
      this.setState({
        nodes: this.prepareNodes(this.props.nodes, pageId),
        activePageId: pageId,
      });
    };

    document.addEventListener('navItemSelected', e => {
      this.setState({
        activePageId: e.detail.activePageId,
        nodes: prepareNodes(this.state.nodes, e.detail.activePageId),
      });
    });

    document.querySelectorAll(".navbar__toggle").forEach(toggle => {
      toggle.addEventListener("click", () => {
        this.setState({visible: !this.state.visible});
      });
    });

    document.addEventListener('click', e => {
      if (e.target.classList.contains('nav__backdrop')) {
        this.setState({visible: false});
      }
    });
  }

  prepareNodes(nodes, activePageId, manuallyExpanded) {
    manuallyExpanded ||= []
    return nodes.map(n => {
      const node = {...n}

      node.active = this.isOrContainsActiveNode(node, activePageId);
      node.expanded = node.active || -1 !== manuallyExpanded.indexOf(node.id);

      if (node.children.length > 0) {
        node.children = this.prepareNodes(node.children, activePageId);
      }

      return node;
    });
  }

  isOrContainsActiveNode(node, activePageId) {
    return node.id === activePageId || (
      node.children.length > 0 && node.children.reduce((memo, child) => {
        return memo || this.isOrContainsActiveNode(child, activePageId);
      }, false)
    );
  }

  handleClick(node) {
    if (node.expand_on_click) {
      const manuallyExpanded = -1 !== this.state.manuallyExpanded.indexOf(node.id);
      this.setState({
        nodes: this.prepareNodes(this.state.nodes, this.state.activePageId, manuallyExpanded ? [] : [node.id]),
        manuallyExpanded: manuallyExpanded ? [] : [node.id],
      });
    } else {
      this.setState({activePageId: node.id}, function () {
        this.setState({
          activePageId: node.id,
          nodes: this.prepareNodes(this.state.nodes, node.id),
        });
      });
      document.dispatchEvent(new CustomEvent('navItemSelected', {detail: {activePageId: node.id}}));
    }
  }

  handleHideNav() {
    this.setState({visible: false});
  }

  render() {
    const navClasses = ["nav"];
    if (this.props.offCanvas) {
      navClasses.push("nav--off-canvas");
    }

    if (this.state.visible) {
      navClasses.push("nav--visible");
    }

    return (
      <nav className={navClasses.join(" ")}>
        <div className="nav__backdrop"/>
        <div className="nav__panel">
          <NavGroup
            nodes={this.state.nodes}
            nopjax={this.props.nopjax}
            onClick={this.handleClick}
            onHideNav={this.handleHideNav}
          />
          <Langselect
            pageId={this.state.activePageId}
            selectedLocale={this.props.selectedLocale}
          />
        </div>
      </nav>
    );
  }
}

Nav.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape(nodeType)).isRequired,
  activePageId: PropTypes.number.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  nopjax: PropTypes.bool.isRequired,
  offCanvas: PropTypes.bool.isRequired,
};
