import React from "react";
import {processedNodeType} from "./node_type";
import {NavGroup} from "./NavGroup";
import * as PropTypes from "prop-types";

export const Item = (props) => {
  const {name, path, append_space, expand_on_click, active, expanded, children, onClick, onHideNav, nopjax} = props;
  const classes = ['nav__item']

  if (true === expanded) {
    classes.push('nav__item--expanded');
  }

  if (append_space) {
    classes.push('nav__item--with-space-below');
  }
  if (active) {
    classes.push('nav__item--active');
  }

  const InnerTagName = expand_on_click ? 'span' : 'a';
  const innerProps = {}

  if (!expand_on_click) {
    innerProps.href = path;
  }

  if (nopjax) {
    innerProps['data-no-pjax'] = true;
  }

  if (expand_on_click) {
    innerProps.onClick = e => {
      e.preventDefault();
      onClick(props)
    };
  } else {
    innerProps.onClick = onHideNav;
  }

  return (
    <li className={classes.join(' ')}>
      <InnerTagName {...innerProps}>
        {name}
      </InnerTagName>
      {children && children.length > 0 && <NavGroup nopjax={nopjax} onClick={onClick} onHideNav={onHideNav} nodes={children}/>}
    </li>
  );
}

Item.propTypes = {
  ...processedNodeType,
  onClick: PropTypes.func.isRequired,
  onHideNav: PropTypes.func.isRequired,
  nopjax: PropTypes.bool.isRequired,
};
