import React from "react";
import * as PropTypes from "prop-types";

const availableLocales = ['de', 'fr', 'en'];

export function Langselect({pageId, selectedLocale}) {
  return (
    <div className='langselect'>
      {availableLocales.map(locale =>
        <a key={locale} data-no-pjax="true" href={`/${locale}/page/${pageId}`} className={`langselect__lang ${locale === selectedLocale ? 'langselect__lang--active' : ''}`}>
          {locale}
        </a>
      )}
    </div>
  );
}

Langselect.propTypes = {
  pageId: PropTypes.number.isRequired,
  selectedLocale: PropTypes.string.isRequired,
};
