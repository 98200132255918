import React from 'react'
import ReactDOM from "react-dom";
import {Nav} from "./Nav";

export function getNavConfig() {
  return JSON.parse(document.getElementById('navConfig').innerText);
}

export function renderNav(activePageId) {
  const config = getNavConfig();
  ReactDOM.render(
    <Nav
      nodes={config.tree}
      activePageId={activePageId}
      nopjax={config.nopjax}
      offCanvas={config.offCanvas}
      selectedLocale={config.selectedLocale}
    />,
    document.getElementById('nav'),
  );
}

export function isOrContainsActiveNode(node, activePageId) {
  return node.id === activePageId || (
    node.children.length > 0 && node.children.reduce((memo, child) => {
      return memo || isOrContainsActiveNode(child, activePageId);
    }, false)
  );
}

export function prepareNodes(nodes, activePageId, manuallyExpanded) {
  manuallyExpanded ||= []
  return nodes.map(n => {
    const node = {...n}

    node.active = isOrContainsActiveNode(node, activePageId);
    node.expanded = node.active || -1 !== manuallyExpanded.indexOf(node.id);

    if (node.children.length > 0) {
      node.children = prepareNodes(node.children, activePageId);
    }

    return node;
  });
}

export function injectNav() {
  const config = getNavConfig();
  document.addEventListener('DOMContentLoaded', () => renderNav(config.active_page_id));
}
