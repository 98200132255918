import anime from 'animejs';
import scrollMonitor from 'scrollmonitor';

const speedFactor = 1.0;
const slides = document.querySelectorAll('.slide');
const container = document.querySelector('.home');
const main = container.querySelector('main');
const loader = container.querySelector('.loader');
let scrollTimeline, introTimeline;
const videoSlide = main.querySelector('#slide3');
window.addEventListener('load', init);
const video = document.getElementById('emoVideo');

// ---------------------------------------------------------------------------------------------------------------------

// document.getElementById('logo').addEventListener('click', e => {
//   e.preventDefault();
//   toggleMenu();
//   document.dispatchEvent(new CustomEvent('toggleMenu'));
// })

function init() {
  initMain();
  initScrollAnimation();
  progressOnScroll();
  disableLoader();
  initIntroAnimation();
  playIntroAnimation();
}

function initMain() {
  document.body.classList.add('intro-playing')
  main.style.height = `${Math.ceil(slides.length * speedFactor * 100)}vh`;
  container.classList.add('animated');
}

function initIntroAnimation() {
  introTimeline = anime.timeline({
    direction: 'normal',
    loop: false,
    easing: 'easeInOutExpo',
    autoplay: false,
    complete: () => {
      document.body.classList.remove('intro-playing');
      activateSlide(1);
    }
  })
    // Slide in logo from right
    .add({targets: '#logo', opacity: [0, 1], duration: 300}, 300)
    // .add({targets: '#logo', translateX: () => [Math.max(500, window.innerWidth / 2), 0], duration: 1000}, 0)
    .add({targets: '#logo', translateY: ['50vh', 0], translateX: ['-50vw', 0], scale: [8.0, 1.0], duration: 2000}, 500)

    // Slide menu up
    // .add({targets: '.desktop-menu', translateY: [100, 0]}, 500)
    .add({targets: '.desktop-menu', opacity: [0, 1], duration: 300}, 750)
    .add({
      targets: '.desktop-menu',
      duration: 1000,
    }, 1000)
    // .add({targets: '.desktop-menu', top: [30, 45], duration: 1000}, 1000)
    .add({targets: '.desktop-menu .with-space-below', marginBottom: [0, 15], duration: 1000}, 1000)

    // Slide in cover product
    .add({targets: '#slide1', opacity: [0, 1], duration: 500}, 1800)
    .add({
      targets: '#coverProduct',
      translateX: () => [-Math.max(500, window.innerWidth / 2), 0],
      duration: 1000
    }, 1800)
    .add({targets: '#slide1 .link-list', opacity: [0, 1], translateX: [-100, 0], duration: 500}, 2200)
}

function activateSlide(n) {
  console.log("ACTIVATE SLIDE", n);
  document.querySelector('#slide' + n.toString()).classList.add('active');
}

function deactivateSlide(n) {
  console.log("DEACTIVATE SLIDE", n);
  document.querySelector('#slide' + n.toString()).classList.remove('active');
}

const slideDuration = 25;

function addSlide(timeline, n, slideStartTime) {
  if (n <= 1) {
    throw Error('The first slide must not be added automatically');
  }

  // const slideStartTime = (n - 2) * slideDuration
  let offset = 0;
  console.log("STEP", n, slideStartTime);

  // const onContentBegin = () => {
  //   if (2 === n) {
  //     document.onMinimizeMenu();
  //   }
  // }

  const isSecondSlide = 2 === n;

  // Fade out link list of previous slide
  timeline.add({
    targets: `#slide${n - 1} .link-list`,
    opacity: [1, 0],
    translateX: [0, 100],
    duration: 3
  }, slideStartTime + (offset += 0))

  // Move out previous slide content.
  timeline.add({
    targets: `#slide${n - 1} .content`,
    opacity: [1, 0],
    translateX: () => [0, 300],
    duration: 5,
  }, slideStartTime + (offset += 0));

  // Fade out previous slide
  timeline.add({
    targets: `#slide${n - 1}`,
    opacity: [1, 0],
    duration: 10,
  }, slideStartTime + (offset += isSecondSlide ? 0 : 5));

  // Push up previous slide background
  timeline.add({
    targets: `#slide${n - 1} .fullscreen-bg`,
    translateY: [0, -(window.innerHeight)],
    easing: 'linear',
    duration: 10,
  }, slideStartTime + offset);

  // Push up next slide background.
  timeline.add({
    targets: `#slide${n} .fullscreen-bg`,
    translateY: [(window.innerHeight), 0],
    easing: 'linear',
    duration: 10,
  }, slideStartTime + offset);

  // Fade in next slide.
  timeline.add({
    targets: `#slide${n}`,
    opacity: [0, 1],
    duration: 10,
  }, slideStartTime + offset);

  // Move in next slide content.
  timeline.add({
    targets: `#slide${n} .content`,
    translateX: () => [-300, 0],
    easing: 'easeInOutQuad',
    opacity: [0, 1],
    duration: 10,
  }, slideStartTime + (offset += 5));

  // Fade in link list of next slide.
  timeline.add({
    targets: `#slide${n} .link-list`,
    opacity: [0, 1],
    translateX: [-100, 0],
    duration: 2,
    changeComplete: (e) => {
      if ('down' === currentScrollDirection) {
        console.log("DOWN");
        deactivateSlide(n - 1);
        activateSlide(n);
      } else if ('up' === currentScrollDirection) {
        console.log("UP");
        activateSlide(n - 1);
        deactivateSlide(n);
      }
    },
    changeBegin: (e) => {
      if ('down' === currentScrollDirection) {
        console.log("DOWN");
        deactivateSlide(n - 1);
        activateSlide(n);
      } else if ('up' === currentScrollDirection) {
        console.log("UP");
        activateSlide(n - 1);
        deactivateSlide(n);
      }
    },
  }, slideStartTime + (offset += 5));

  console.log("Slide duration", offset + 2);
}

let lastProgress = null;
let currentScrollDirection = null;
let scrolledUpHeight = 0;
let scrolledDownHeight = 0;
let menuHidden = false; // null means animation in progress.

function detectDirectionAndDistance(currentProgress) {
  if (lastProgress > currentProgress) {
    if ('up' === currentScrollDirection) {
      scrolledUpHeight += (lastProgress - currentProgress);
      scrolledDownHeight = 0;
    } else {
      scrolledUpHeight = 0;
      scrolledDownHeight = 0;
    }
    currentScrollDirection = 'up';

  } else if (currentProgress > lastProgress) {
    if ('down' === currentScrollDirection) {
      scrolledUpHeight = 0;
      scrolledDownHeight += (currentProgress - lastProgress);
    } else {
      scrolledUpHeight = 0;
      scrolledDownHeight = 0;
    }
    currentScrollDirection = 'down';
  }
  lastProgress = currentProgress;
}

function initScrollAnimation() {
  scrollTimeline = anime.timeline({
    direction: 'alternate',
    loop: true,
    easing: 'linear',
    autoplay: false,
    update: (anim) => {
      detectDirectionAndDistance(anim.progress);

      if (false === menuHidden && scrolledDownHeight > 5) {
        hideMenu();
      } else if (menuHidden && anim.progress <= 5) {
        showMenu();
      }

      const threshold = 0.5;
      if (videoSlide.style.opacity > threshold) {
        video.play();
        // startVideoCanvasPlayback();
      } else if (videoSlide.style.opacity < threshold) {
        video.pause();
        // pauseVideoCanvasPlayback();
      }
    }
  });

  // Really not sure why this is necessary. Anime.js doesn't seem to recognize this when
  // seek()ing when initialized through addSlide().
  // scrollTimeline.add({targets: '#slide1 .content', opacity: [1, 0], duration: 1}, 4);
  // scrollTimeline.add({targets: '#slide1 .fullscreen-bg', opacity: [1, 0], duration: 1}, 4);

  let offset = 0;
  addSlide(scrollTimeline, 2, (offset));
  addSlide(scrollTimeline, 3, (offset += slideDuration));
  addSlide(scrollTimeline, 4, (offset += slideDuration * 0.5));
  addSlide(scrollTimeline, 5, (offset += slideDuration));
  seek();
}

function toggleMenu() {
  if (false === menuHidden) {
    hideMenu();
  } else {
    showMenu();
  }
}

function showMenu() {
  menuHidden = null;
  anime({
    targets: '.desktop-menu',
    easing: 'easeInOutExpo',
    opacity: [0, 1],
    duration: 300,
    complete: () => menuHidden = false
  });
}

function hideMenu() {
  menuHidden = null;
  anime({
    targets: '.desktop-menu',
    easing: 'easeInOutExpo',
    opacity: [1, 0],
    duration: 500,
    complete: () => menuHidden = true
  });
}

function progressOnScroll() {
  window.addEventListener('scroll', e => {
    const navbar = document.querySelector(".navbar");
    if (window.scrollY > 50) {
      navbar.classList.add("navbar--scrolled");
    } else {
      navbar.classList.remove("navbar--scrolled");
    }
    seek();
  });
}

function seek() {
  scrollTimeline.seek(getScrollProgress() * scrollTimeline.duration);
}

function getScrollProgress() {
  return window.scrollY / (document.body.scrollHeight - window.innerHeight);
}

function disableLoader() {
  setTimeout(() => {
    loader.classList.add('hide');
  }, 1);
}

function playIntroAnimation() {
  if (isPageScrolled()) {
    introTimeline.seek(introTimeline.duration);
  } else {
    introTimeline.play();
  }
}

export function isPageScrolled() {
  return scrollMonitor.create(document.getElementById('top')).isAboveViewport;
}
