import React from "react";
import * as PropTypes from "prop-types";
import {processedNodeType} from "./node_type";
import {Item} from "./Item";

export const NavGroup = ({nodes, onClick, onHideNav, nopjax}) => {
  return (
    <ul className="nav__group">
      {nodes.map(node => node.visible && <Item
        key={node.id} {...node}
        nopjax={nopjax}
        onClick={onClick}
        onHideNav={onHideNav}
      />)}
    </ul>
  );
}

NavGroup.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape(processedNodeType)).isRequired,
  onClick: PropTypes.func.isRequired,
  nopjax: PropTypes.bool.isRequired,
};
